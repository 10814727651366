import React from "react"

import Title from 'components/Title'
import Constrain from 'components/Constrain'
import Video from 'components/Video'

import Container from 'components/Container'

import image from 'images/customer-history.png'
// import { useSiteMetadata } from "../../hooks/useSiteMetadata"

import { useTranslation } from 'gatsby-plugin-react-i18next'

import Footer from "components/Footer"
import Header from "components/Header"

export default function Home() {

  const { t } = useTranslation()

  // const { title } = useSiteMetadata()

  return (
    <>
    <Header />
    <main>
      <Title 
        title={ t('Customer relationships') } 
        subtitle={ t('Build lasting relationships with your customers') }
        dark 
      />
      <Constrain>
        <Video poster={ image } src='https://autoservice.nyc3.cdn.digitaloceanspaces.com/media/customer-history.mp4' />
      </Constrain>

      <Container>

        <h2>{ t('See a complete history of your relationship with your customers') }</h2>

        <p>
          { t("Our unique timeline view gives you an at-a-glace view of every appointment for a customer and it's status.") }
        </p>
        <p>
          { t("Need more info on a particular appointment? Just click in and see every comment, attachment and photo.") }
        </p>

        <h2>{ t("Get to know how your customers see your company") }</h2>

        <p>
          { t("Built-in NPS surveys capture customer satisfaction over time and give you valuable feedback.") }
        </p>

      </Container>
  </main>
  <Footer />
  </>
  )
}
